import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/angel/conceptsauce/syrup/packages/site/src/templates/mdx.js"
import Editor from 'src/components/editor'
import Console from 'src/components/console'

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>


<MDXTag name="h2" components={components}>{`Access the Full RAW Data Object`}</MDXTag>
<MDXTag name="p" components={components}>{`There maybe times when you need access to the full RAW data object returned from our survey data endpoint and in the past we advised clients to make a direct call to that endpoint using whatever code they chose.`}</MDXTag>
<MDXTag name="p" components={components}>{`This has proved problamatic to support as well as leading to faults and errors on clients survey result web pages and alike.`}</MDXTag>
<MDXTag name="p" components={components}>{`As such from now on the only supported way to access survey data is through the Syrup library.`}</MDXTag>
<MDXTag name="p" components={components}>{`Using the Syrup library provides built in error trapping when calling the endpoint and can present you with the raw data object as well as the more usual `}<code className="language-text">TaffyDB</code>
{` `}<code className="language-text">qObj</code>
{`.`}</MDXTag>
<div className="gatsby-highlight" data-language="javascript"><pre className="language-javascript"><code className="language-javascript">console<span className="token punctuation">.</span><span className="token function">log</span><span className="token punctuation">(</span>r<span className="token punctuation">.</span>rawData<span className="token punctuation">)</span></code></pre></div>

<div className="gatsby-highlight" data-language="javascript"><pre className="language-javascript"><code className="language-javascript"><span className="token keyword">var</span> r <span className="token operator">=</span> <span className="token keyword">await</span> syrup<span className="token punctuation">.</span><span className="token function">fetchRespondentData</span><span className="token punctuation">(</span><span className="token punctuation">{"{"}</span>{"\n"}{"  "}projectID<span className="token punctuation">:</span> <span className="token string">'8fcbb2dc-8093-40d7-6c41-88ecccdcb2aa'</span><span className="token punctuation">,</span>{"\n"}{"  "}pageID<span className="token punctuation">:</span> <span className="token string">'b8b70dca-a5b8-4a1a-4a8f-f60254fea95c'</span><span className="token punctuation">,</span>{"\n"}{"  "}respondentID<span className="token punctuation">:</span> <span className="token string">'448e384d1cf3a2a5b44f63cce5aeb36f'</span><span className="token punctuation">,</span>{"\n"}<span className="token punctuation">{"}"}</span><span className="token punctuation">)</span>{"\n"}console<span className="token punctuation">.</span><span className="token function">log</span><span className="token punctuation">(</span>r<span className="token punctuation">.</span>rawData<span className="token punctuation">)</span></code></pre></div>

<Editor hideRelatedCode example="real-example" />
<Console example="real-example" />
           </MDXTag>
  }
}

export const _frontmatter = {};

  